import React from "react";
import singupImage from "./images/signup.jpg";

const HowItWorks = () => {
  return (
    <section
      className="how-it-works"
      // style={{
      //   backgroundImage: `url(${singupImage})`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      //   backgroundRepeat: "no-repeat",
      // }}
    >
      <div className="how-it-works-text-box">
        <h2>How It Works</h2>
        <ol>
          <li>Sign up</li>
          <li>Connect your bank account securely</li>
          <li>Import into your Excel sheet</li>
          <li>Update it with one click</li>
          <li>Manage your budget and track finances</li>
        </ol>
      </div>
    </section>
  );
};

export default HowItWorks;
