import React from "react";

const CallToAction = () => {
  return (
    <section className="cta">
      <h2>Get Started Today</h2>
      <a href="/signup" className="cta-button">
        Sign Up Now!
      </a>
    </section>
  );
};

export default CallToAction;
