// <p className="intro-text">
// Imagine having all your bank transactions at your fingertips in a
// dynamic, easy-to-use Excel sheet.
// </p>
// <p className="intro-text highlight">
// Keep it updated with the latest data from your bank with just one click.
// </p>
// CopyWriting component
import React from "react";

const CopyWriting = () => {
  return (
    <div className="copywriting-box">
      <div>
        <p className="intro-text">
          Imagine having all your bank transactions at your fingertips in a
          dynamic, easy-to-use Excel sheet.
        </p>
        <p className="intro-text highlight">
          Stay up-to-date with the latest data from your bank with a single
          click.
        </p>
      </div>
    </div>
  );
};

export default CopyWriting;
