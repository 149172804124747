import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./viewSettingsPage.module.css";

const ViewSettingsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialSettings = location.state?.settings || {};
  const [editableSettings, setEditableSettings] = useState(initialSettings);
  const [notification, setNotification] = useState(null);

  const orderedSettingsKeys = [
    "sendEmails",
    "sendOnlyOutgoingTransactions",
    "sendingOnlyIncomingTransactions",
    "sendOnlyTrasactionsAbove",
  ];

  const handleInputChange = (event, key) => {
    const { type, checked, value } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setEditableSettings((prevState) => ({
      ...prevState,
      emailSettings: {
        ...prevState.emailSettings,
        [key]: newValue,
      },
    }));
  };

  const handleSave = async () => {
    const updatedSettings = {
      emailSettings: editableSettings.emailSettings,
    };

    try {
      const response = await fetch(
        "https://kleinmanshloma-open-banking-server.onrender.com/api/v1/settings/update",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updatedSettings),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update settings");
      }

      const updatedSettingsResponse = await response.text();

      console.log(updatedSettingsResponse?.message || updatedSettingsResponse);

      setNotification(updatedSettingsResponse);
      setTimeout(() => {
        setNotification(null);
        navigate("/BankAccountsData");
      }, 2000);
    } catch (error) {
      console.error(error.message);
    }
  };

  if (!editableSettings.emailSettings) {
    return <div>Loading settings...</div>;
  }

  const splitCamelCaseString = (camelCaseString) => {
    const splitString = camelCaseString.replace(/([a-z])([A-Z])/g, "$1 $2");
    return splitString.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleCloseSettings = () => {
    navigate("/BankAccountsData");
  };

  return (
    <div className={styles.settingsPageContainer}>
      <span className={styles.settingsCloseBtn} onClick={handleCloseSettings}>
        X
      </span>
      <h2>Settings</h2>
      <div className={styles.settingsListContainer}>
        <ul className={styles.settingsList}>
          {orderedSettingsKeys.map((key) => (
            <li key={key} className={styles.settingsItem}>
              <label htmlFor={key}>{splitCamelCaseString(key)}:</label>
              {key === "sendOnlyTrasactionsAbove" ? (
                <input
                  id={key}
                  type="number"
                  step={10}
                  value={editableSettings.emailSettings[key]}
                  onChange={(event) => handleInputChange(event, key)}
                  className={styles.inputField}
                />
              ) : typeof editableSettings.emailSettings[key] === "boolean" ? (
                <label className={styles.switch}>
                  <input
                    id={key}
                    type="checkbox"
                    checked={editableSettings.emailSettings[key]}
                    onChange={(event) => handleInputChange(event, key)}
                  />
                  <span className={styles.slider}></span>
                </label>
              ) : (
                <input
                  id={key}
                  type="text"
                  value={editableSettings.emailSettings[key]}
                  onChange={(event) => handleInputChange(event, key)}
                  className={styles.inputField}
                />
              )}
            </li>
          ))}
        </ul>
        <button onClick={handleSave} className={styles.saveButton}>
          Save
        </button>
      </div>
      {notification && (
        <div className={styles.notification}>{notification}</div>
      )}
    </div>
  );
};

export default ViewSettingsPage;
