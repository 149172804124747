import React from "react";
import Header from "./Header";
import CopyWriting from "./CopyWriting";
import Features from "./Features";
import HowItWorks from "./HowItWorks";
import Difference from "./Difference";
import CallToAction from "./CallToAction";
import Feedback from "./Feedback";
import Footer from "./Footer";
import financial from "./images/finance.jpg";
import "./styles.css";

const MainPage = () => {
  return (
    <div
      className="container"
      style={{
        backgroundImage: `url(${financial})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Header />
      <CopyWriting />
      <Features />
      <HowItWorks />
      <Difference />
      <CallToAction />
      <Feedback />
      <Footer />
    </div>
  );
};

export default MainPage;
