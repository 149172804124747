import React, { useState } from "react";
import "./Footer.css";

const Footer = () => {
  const [showFAQ, setShowFAQ] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [showLegalInfo, setShowLegalInfo] = useState(false);

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="expandable-section">
          <h2 onClick={() => setShowFAQ(!showFAQ)}>
            FAQ {showFAQ ? "▲" : "▼"}
          </h2>
          {showFAQ && (
            <div className="section-content">
              <h3>What is Bank Accounts in Excel?</h3>
              <p>
                A web app that syncs your bank transactions into an Excel sheet
                automatically.
              </p>
              <h3>How does it work?</h3>
              <p>
                Sign up, connect your bank, get it imported into your Excel
                sheet, and update it with one click.
              </p>
              <h3>Is it secure?</h3>
              <p>Yes, we use bank-level security to protect your data.</p>
            </div>
          )}
        </div>

        <div className="expandable-section">
          <h2 onClick={() => setShowContactInfo(!showContactInfo)}>
            Contact Us {showContactInfo ? "▲" : "▼"}
          </h2>
          {showContactInfo && (
            <div className="section-content">
              <p>
                Email:{" "}
                <a href="mailto:shloma@perfectbusinessoverview.com">
                  shloma@perfectbusinessoverview.com
                </a>
              </p>
              <p>Phone: +1 347 915 5773</p>
            </div>
          )}
        </div>

        <div className="expandable-section">
          <h2 onClick={() => setShowLegalInfo(!showLegalInfo)}>
            Legal {showLegalInfo ? "▲" : "▼"}
          </h2>
          {showLegalInfo && (
            <div className="section-content">
              <h3>About Us</h3>
              <p>
                Bank Accounts in Excel was created by
                perfectbusinessoverview.com in 2024.
              </p>
              <h3>Privacy Policy</h3>
              <p>We do not collect or store any personal information.</p>
              <h3>Terms of Service</h3>
              <p>By using our service, you agree to our Terms of Service.</p>
              <h3>Disclaimer</h3>
              <p>
                We are not responsible for any errors or omissions in the Excel
                sheets.
              </p>
            </div>
          )}
        </div>
      </div>

      <p>Thank you for using Bank Accounts in Excel!</p>
    </footer>
  );
};

export default Footer;
