import React from "react";

const Feedback = () => {
  return (
    <section className="feedback">
      <h2>Feedback</h2>
      <p>
        Have feedback?{" "}
        <a href="mailto:shloma@perfectbusinessoverview.com">Let us know</a>.
      </p>
    </section>
  );
};

export default Feedback;
