import React from "react";

const Difference = () => {
  return (
    <section className="difference">
      <h2>What Makes Us Different</h2>
      <div className="difference-list">
        <div className="difference-item">
          <p>Save time with automated updates</p>
        </div>
        <div className="difference-item">
          <p>Cost-effective financial management</p>
        </div>
        <div className="difference-item">
          <p>Top-notch security and privacy</p>
        </div>
        <div className="difference-item">
          <p>User-friendly interface</p>
        </div>
        <div className="difference-item">
          <p>Seamless integration with Excel</p>
        </div>
        <div className="difference-item">
          <p>Personalized support and assistance</p>
        </div>
      </div>
    </section>
  );
};

export default Difference;
