import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

import styles from "./Signup.module.css";

import config from "../../config/config";

const Signup = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    username: "",
    email: "",
    companyName: "",
    password: "",
    confirmPassword: "",
    approvalCode: "",
  });

  const [errorMessages, setErrorMessages] = useState("");

  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [errorResponse, setErrorResponse] = useState(null);

  const showResponse3Seconds = () => {
    setTimeout(() => {
      setResponse(null);
      setErrorResponse("");
      navigate("/Login");
    }, 3500);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "confirmPassword") {
      if (userData.password !== value) {
        setErrorMessages("Passwords do not match");
      } else {
        setErrorMessages("");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userData.password !== userData.confirmPassword) {
      setErrorMessages("Passwords do not match");
      return;
    }

    console.log("User data submitted", userData);

    try {
      const response = await fetch(`${config.URL_PROD}/api/v1/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        setErrorResponse(errorResponse.message);
        showResponse3Seconds();
        return;
      }

      const result = await response.json();

      setResponse(`User created successfully!`);
      showResponse3Seconds();
    } catch (error) {
      console.error("Network error:", error);
      setErrorResponse("Network error occurred. Please try again.");
      showResponse3Seconds();
    }
  };

  useEffect(() => {
    /* console.log("User Data: ", userData); */
  }, [userData]);

  return (
    <div className={styles.signupPage}>
      <h1 className={styles.header}>
        <b>Bank Accounts in Excel</b>
      </h1>
      {response && <p className={styles.responseMessage}>{response}</p>}
      {errorResponse && <p className={styles.errorMessage}>{errorResponse}</p>}
      <div className={styles.signupBox}>
        <div className={styles.illustrationWrapper}>
          <img
            src="https://mixkit.imgix.net/art/preview/mixkit-left-handed-man-sitting-at-a-table-writing-in-a-notebook-27-original-large.png?q=80&auto=format%2Ccompress&h=700"
            alt="Signup Illustration"
          />
        </div>
        <form className={styles.signupForm} onSubmit={handleSubmit}>
          <h2 className={styles.title}>Sign Up</h2>
          <div className={styles.formGroup}>
            <label className={styles.label}>Username</label>
            <input
              type="text"
              name="username"
              className={styles.input}
              value={userData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Email Address</label>
            <input
              type="email"
              name="email"
              className={styles.input}
              value={userData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Company Name</label>
            <input
              type="text"
              name="companyName"
              className={styles.input}
              value={userData.companyName}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Password</label>
            <input
              type="password"
              name="password"
              className={styles.input}
              value={userData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              className={styles.input}
              value={userData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>
          {/* add an Approval code this will approve the user automaticlly while sign up*/}
          <div className={styles.formGroup}>
            <label className={styles.label}>Approval Code</label>
            <input
              type="text"
              name="approvalCode"
              className={styles.input}
              value={userData.approvalCode}
              onChange={handleChange}
            />
          </div>
          {errorMessages && (
            <div className={styles.errorMessage}>{errorMessages}</div>
          )}
          <div className={styles.signinLink}>
            <NavLink to="/Login">Already signed up?</NavLink>
          </div>
          <button type="submit" className={styles.signupButton}>
            SIGN UP
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
