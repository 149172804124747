import React from "react";

const Features = () => {
  return (
    <section className="features">
      <h2>Why You'll Love It</h2>
      <div className="feature-list">
        <div className="feature-item">
          <p>Automatically sync your bank transactions to Excel</p>
        </div>
        <div className="feature-item">
          <p>One-click updates for the latest data</p>
        </div>
        <div className="feature-item">
          <p>Simplify your budgeting process</p>
        </div>
        <div className="feature-item">
          <p>Effortlessly track expenses and income</p>
        </div>
        <div className="feature-item">
          <p>Secure and easy to use</p>
        </div>
      </div>
    </section>
  );
};

export default Features;
