import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config/config";
import styles from "./settings.module.css";

const Settings = () => {
  const navigate = useNavigate();
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch(`${config.URL_PROD}/api/v1/settings`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await response.json();
        setSettings(data.settings);
      } catch (error) {
        console.error("Error fetching settings: ", error);
      }
    };

    fetchSettings();
  }, []);

  const onSettingsClicked = () => {
    navigate("/ViewSettingsPage", { state: { settings } });
  };

  return (
    <div className={styles.settingsContainer}>
      <div>
        <svg
          viewBox="0 -960 960 960"
          style={{
            fill: "black",
            border: "2px solid black",
            borderRadius: "50%",
            padding: "5px",
            cursor: "pointer",
            marginLeft: "2.5rem",
            width: "2.5rem",
          }}
          className={styles.icon}
          onClick={onSettingsClicked}
        >
          <path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm112-260q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm0-80q-25 0-42.5-17.5T422-480q0-25 17.5-42.5T482-540q25 0 42.5 17.5T542-480q0 25-17.5 42.5T482-420Zm-2-60Zm-40 320h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Z" />
        </svg>
      </div>
    </div>
  );
};

export default Settings;
