import React from "react";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

import MainPage from "./components/MainPage/MainPage";
import Signup from "./components/signup/Signup";
import Login from "./components/login/Login";
import ConnectToBank from "./components/banks/ConnectToBank";
import BankAccountsData from "./components/BankData/BankAccountsData";
import ViewSettingsPage from "./components/settings/ViewSettingsPage";

function App() {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [accountsID, setAccountsID] = useState(null);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route
          path="/Login"
          element={<Login userData={userData} setUserData={setUserData} />}
        />
        <Route path="/Signup" element={<Signup />} />
        <Route
          path="/ConnectToBank"
          element={
            <ConnectToBank
              userData={userData}
              setUserData={setUserData}
              accountsID={accountsID}
              setAccountsID={setAccountsID}
            />
          }
        />
        <Route path="/BankAccountsData" element={<BankAccountsData />} />
        <Route path="/ViewSettingsPage" element={<ViewSettingsPage />} />
      </Routes>
    </Router>
  );
}

export default App;
