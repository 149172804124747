import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TransactionTable from "./TransactionTable";
import styles from "./BankDataComponent.module.css";
import config from "../../config/config";
import Settings from "../settings/Settings";

// Importing images
import click1 from "../images/Click1.png";
import click2 from "../images/Click2.png";
import click3 from "../images/click3.png";
import click4 from "../images/click4.png";
import click5 from "../images/Step 5 - 6.png";
import click7 from "../images/step 7.png";
import click8 from "../images/step 8.png";
import click9 from "../images/step 9.png";
import click10 from "../images/step 10.png";

const BankAccountsData = () => {
  const navigate = useNavigate();

  const [accounts, setAccounts] = useState([]);
  const [accountIds, setAccountIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [expandedInstitution, setExpandedInstitution] = useState(null);
  const [expandedIBAN, setExpandedIBAN] = useState(null);
  const [transactions, setTransactions] = useState({});
  const [expandedTransaction, setExpandedTransaction] = useState(null);
  const [expandUrls, setExpandUrls] = useState(false);
  const [expandBankConnectionsAmount, setExpandBankConnectionsAmount] =
    useState(false);
  const [searchTransaction, setSearchTransaction] = useState("");

  const [messagePositive, setMessagePositive] = useState(null);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [excelInstructions, setExcelInstructions] = useState(false);

  const handleExcelInstructionsClick = () => {
    setExcelInstructions(!excelInstructions);
  };

  const handleSearchTransaction = (e) => {
    setSearchTransaction(e.target.value);
  };

  const toggleInstitution = (institutionId) => {
    setExpandedTransaction(null);
    setExpandedInstitution(
      expandedInstitution === institutionId ? null : institutionId
    );
  };

  const toggleIBAN = async (iban) => {
    setExpandedTransaction(false);
    if (expandedIBAN === iban) {
      setExpandedIBAN(null);
      setSearchTransaction(""); // Reset search term when IBAN is closed
    } else {
      setExpandedIBAN(iban);
      if (!transactions[iban]) {
        setTransactionLoading(true);
        const findAccount = accounts.find((account) => account.iban === iban);
        const accountId = findAccount.id;
        try {
          const response = await fetch(
            `${config.URL_PROD}/api/v1/bank/transactionsAsked/${accountId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (!response.ok) {
            throw new Error(
              `Failed to fetch transactions: ${response.statusText}`
            );
          }
          const result = await response.json();

          /*  console.log("Transactions:", result?.transactionsDataWithMerchant); */
          setTransactions((prevTransactions) => ({
            ...prevTransactions,
            [iban]: result.transactionsDataWithMerchant,
          }));
          /*    console.log("Transactions:", result); */
        } catch (error) {
          console.error("Error fetching transactions:", error.message);
          //TODO: Optionally set an error message state for user feedback
        } finally {
          setTransactionLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${config.URL_PROD}/api/v1/bank/getAccountsAndBalances`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        // console.log("response", response);
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.statusText}`);
        }

        const result = await response.json();

        /* console.log("Bank data:", result); */
        if (
          result.message &&
          result.message?.startsWith("Your requisitions have expired.")
        ) {
          return setErrorMessage(result.message);
        }
        if (result.message?.startsWith("Failed to fetch account")) {
          return setErrorMessage(result.message);
        }

        if (result.message === "No accounts with balances found") {
          setErrorMessage(
            "Sorry but we are working on the issue and will be back soon"
          );
          const timer = setTimeout(() => {
            setErrorMessage(null);
          }, 1500);
          return setAccounts([]);
        }

        // please write what to do ecxept the result should be
        // an array of objects with the following keys:
        // id, institution_id, iban, bankName, bankLogo, balanceAmount, referenceDate
        setAccounts(result);
        setAccountIds(result.map((account) => account.id));
      } catch (error) {
        console.error("Error fetching data:", error.message);
        if (error.message === "Failed to fetch data: Unauthorized") {
          navigate("/Login");
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [navigate]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (errorMessage) {
    return (
      <div className={styles.errorMessage}>
        {/* add an X to exit earlier */}
        <button
          className={styles.errorCloseBtn}
          onClick={() => setErrorMessage(null)}
        >
          X
        </button>
        <p>{errorMessage}</p>
      </div>
    );
  }

  const groupedAccounts = accounts?.reduce((acc, account) => {
    if (!acc[account.institution_id]) {
      acc[account.institution_id] = [];
    }
    acc[account.institution_id].push(account);
    return acc;
  }, {});

  const userId = localStorage.getItem("userId");
  const amountOfRequisitions = localStorage.getItem("amountOfRequisitions");
  const requisitionLength = localStorage.getItem("Requisition Length");
  const isRequisitionLimitReached = amountOfRequisitions === requisitionLength;
  const MayGetExtraUrlForEachAccountNumberForExcel =
    localStorage.getItem("getExtraUrlForEachAccountNumberForExcel") === "true";

  const handleAddAccountClick = () => {
    setMessage(
      "You have reached the limit. Contact support to increase the limit."
    );
    setTimeout(() => {
      setMessage(null);
    }, 4000);
  };

  const handlePositiveMessageOnClick = (message) => {
    setMessagePositive(message);
    setTimeout(() => {
      setMessagePositive(null);
    }, 2000);
  };

  const getCurrencySymbol = (currency) => {
    return currency
      ? new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currency,
        })
          .format(0)
          .replace(/\d/g, "")
          .replace(/\./g, "")
      : "N/A";
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.navbar}>
        <h2 className={styles.header}>Bank Data</h2>
        <Settings />
        {/* logout */}
        <div className={styles.addAnotherAccount}>
          <button
            className={styles.logoutBtn}
            onClick={() => {
              localStorage.removeItem("token");
              navigate("/Login");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25 20"
              fill="currentColor"
              className={styles.logoutIcon}
              width="60px"
              height="60px"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M10.09 15.59L8.67 17l-5-5 5-5 1.41 1.41L6.83 11H18v2H6.83l3.26 3.59zM20 3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H4V5h16v14z" />
            </svg>
            <p>Logout</p>
          </button>
        </div>
      </div>
      {messagePositive && (
        <div className={styles.messagePositive}>
          <p>{messagePositive}</p>
        </div>
      )}

      <div className={styles.headerBox}>
        {/* some nice clickable text to see connections amount and limit make it short*/}
        <div
          onClick={() =>
            setExpandBankConnectionsAmount(!expandBankConnectionsAmount)
          }
        >
          <p className={styles.connectionsAmount}>connections Limit</p>
          {expandBankConnectionsAmount && (
            <div className={styles.requisitionContainer}>
              <div className={styles.requisitionLength}>
                <div className={styles.requisitionInfo}>
                  <p>
                    <strong>Requisition Amount:</strong> {requisitionLength}
                  </p>

                  <p>
                    <strong>Requisition Limit:</strong> {amountOfRequisitions}
                  </p>
                </div>
                {!isRequisitionLimitReached && (
                  <div className={styles.addAnotherAccountBtn}>
                    <button onClick={() => navigate("/ConnectToBank")}>
                      Add Another Account
                    </button>
                  </div>
                )}
                {isRequisitionLimitReached && (
                  <div className={styles.addAnotherAccountBtn}>
                    <button onClick={handleAddAccountClick}>
                      Add Another Account
                    </button>
                  </div>
                )}
                {isRequisitionLimitReached && (
                  <div className={styles.contactSupportMessage}>
                    <p>
                      You have reached the limit. Contact support to increase
                      the limit.
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={styles.copyUrlBox}>
          <div
            className={styles.copyUrlTitle}
            onClick={() => setExpandUrls(!expandUrls)}
          >
            ⬇️ Copy url for Excel Data Import ⬇️
          </div>

          {MayGetExtraUrlForEachAccountNumberForExcel && expandUrls && (
            <div className={styles.accountIds}>
              {/* instructions */}
              <div className={styles.excelInstructions}>
                <button onClick={handleExcelInstructionsClick}>
                  {excelInstructions ? "Hide" : "Show"} Instructions
                </button>
                {excelInstructions && (
                  <div className={styles.instructions}>
                    <p>To import the data into Excel, follow these steps:</p>
                    <ol>
                      <li>
                        Copy the URL of the account you want to import by
                        clicking on it.
                      </li>
                      <li>
                        In Excel, go to the "Data" tab and click on "Get Data".
                        <img
                          className={styles.imageInstructions}
                          src={click1}
                          alt="click1"
                        />
                      </li>
                      <li>
                        Choose "From Web".
                        <img
                          className={styles.imageInstructions}
                          src={click2}
                          alt="click2"
                        />
                      </li>
                      <li>
                        Paste the URL into the box and click "OK". To import the
                        data into Excel.
                        <img
                          className={styles.imageInstructions}
                          src={click3}
                          alt="click3"
                        />
                      </li>
                      <li>
                        Rename the query to the account number IBAN or any
                        meaningful name. And Then Click Into Table.
                        <img
                          className={styles.imageInstructions}
                          src={click4}
                          alt="click4"
                        />
                      </li>
                      <li>
                        Remove The first row and Expand the second By clicking
                        on the 2 arrows.
                        <img
                          className={styles.imageInstructions}
                          src={click5}
                          alt="click5"
                        />
                      </li>
                      <li>
                        Click on the expand To New Rows.
                        <img
                          className={styles.imageInstructions}
                          src={click7}
                          alt="click7"
                        />
                      </li>
                      <li>
                        Click on the expand To New Col.
                        <img
                          className={styles.imageInstructions}
                          src={click8}
                          alt="click8"
                        />
                      </li>
                      <li>
                        Click To Oncheck Use original column name as prefix. And
                        Click OK.
                        <img
                          className={styles.imageInstructions}
                          src={click9}
                          alt="click9"
                        />
                      </li>
                      <li>
                        Click Close & Load To Finish.
                        <img
                          className={styles.imageInstructions}
                          src={click10}
                          alt="click10"
                        />
                      </li>
                      <li>
                        You are all done. Now you can update with just Clicking
                        Refresh in the Data Tab.
                      </li>
                    </ol>
                  </div>
                )}
              </div>
              <ol>
                {accountIds.map((id) => (
                  <li
                    className={styles.accountId}
                    key={id}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://kleinmanshloma-open-banking-server.onrender.com/api/v1/transactions/${userId}/${id}`
                      );
                      handlePositiveMessageOnClick(
                        `${
                          accounts.find((account) => account.id === id).iban ||
                          id
                        } URL copied to clipboard`
                      );
                    }}
                  >
                    <span>
                      Account Number / Iban{" "}
                      {accounts.find((account) => account.id === id).iban || id}
                    </span>
                    <span className={styles.copyIcon}>📋</span>
                  </li>
                ))}
              </ol>
              {/* accountIds.length>0 */}
              {accountIds?.length > 0 && (
                <ul className={styles.allInOneSheet}>
                  {/* copy me for all accounts in one url */}
                  <li
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://kleinmanshloma-open-banking-server.onrender.com/api/v1/transactions/${userId}`
                      );
                      handlePositiveMessageOnClick(
                        `All accounts URL copied to clipboard`
                      );
                    }}
                  >
                    <span>All in one sheet</span>
                    <span className={styles.copyIcon}>📋</span>
                  </li>
                </ul>
              )}
            </div>
          )}
          {message && (
            <div className={styles.requisitionLimitReached}>
              <p>{message}</p>
            </div>
          )}
        </div>
      </div>

      {Object.keys(groupedAccounts).map((institutionId) => (
        <div key={institutionId} className={styles.container}>
          {/* {console.log("groupedAccounts", groupedAccounts)} */}
          <h3
            className={styles.title}
            onClick={() => {
              toggleInstitution(institutionId);
              /*  console.log("institutionId", institutionId); */
            }}
          >
            <span className={styles.bankHeader}>
              Bank Name {groupedAccounts[institutionId][0].bankName || "N/A"}:
            </span>{" "}
            <span>
              <img
                className={styles.logo}
                src={groupedAccounts[institutionId][0].bankLogo || "N/A"}
                alt="Bank Logo"
                title="Bank Logo"
              />
            </span>
            <div>
              <strong>Total Balance:</strong>{" "}
              {groupedAccounts[institutionId]
                .reduce(
                  (acc, account) =>
                    acc +
                    Number(
                      account.balanceAmount.amount || account.balanceAmount
                    ),
                  0
                )
                .toLocaleString("en-BE", {
                  style: "currency",
                  currency: "EUR",
                })
                .replace(/\.00$/, "")}
            </div>
            <p>
              <strong>Accounts Iban:</strong>{" "}
              {
                groupedAccounts[institutionId].map((account) => {
                  return <p key={account.id}>{account.iban}</p>;
                })
                // optionally join the IBANs with a separator
                /*  .join(", ") */
              }
            </p>
          </h3>
          {expandedInstitution === institutionId &&
            groupedAccounts[institutionId].map((account) => (
              <div
                key={account.id} // Use a unique key if 'id' is available
                className={styles.BankAccountsDataContainer}
              >
                <div className={styles.bankData}>
                  <div className={styles.accountBicName}>
                    {/* <span>
                      <strong>Name:</strong> {account.bankName || "N/A"}
                    </span> */}
                    <div className={styles.accountDetails}>
                      <div className={styles.balanceRow}>
                        <strong>Balance:</strong>{" "}
                        {getCurrencySymbol(
                          account?.currency || account.balanceAmount.currency
                        ) +
                          " " +
                          Number(
                            account.balanceAmount.amount ||
                              account?.balanceAmount
                          )}
                      </div>
                      <div className={styles.balanceRow}>
                        <strong>Account Currency:</strong>{" "}
                        {account?.balanceAmount?.currency}
                      </div>
                    </div>
                    <span>
                      <strong>Institution ID / Bic:</strong>{" "}
                      {account.institution_id}
                    </span>
                  </div>
                  <div
                    onClick={() => toggleIBAN(account.iban)}
                    className={`${styles.accountItem} ${
                      expandedIBAN === account.iban ? styles.expanded : ""
                    }`}
                  >
                    <div
                      className={styles.ibanBAnkSearch}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <span
                        className={styles.btnForTransactions}
                        onClick={() => toggleIBAN(account.iban)}
                      >
                        <strong>IBAN:</strong> {account.iban}
                      </span>
                      {/* a search for a transaction */}
                      {expandedIBAN === account.iban && (
                        <span className={styles.searchTransaction}>
                          <input
                            type="text"
                            placeholder="Search Transaction"
                            className={styles.searchTransactionInput}
                            value={searchTransaction}
                            onChange={(e) => {
                              // remove the empty spaces from the search term
                              setSearchTransaction(e.target.value.trim());
                            }}
                            // onClick don't close the expandedIBAN
                            onClick={(e) => e.stopPropagation()}
                          />
                        </span>
                      )}
                    </div>
                    <div className={styles.accountItem}>
                      {transactionLoading && expandedIBAN === account.iban ? (
                        <p>Loading transactions...</p>
                      ) : (
                        expandedIBAN === account.iban &&
                        transactions[account.iban] && (
                          <TransactionTable
                            transactions={transactions}
                            account={account}
                            handleSearchTransaction={searchTransaction}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default BankAccountsData;
