import React from "react";

const Header = () => {
  return (
    <header className="header">
      <h2>Effortlessly Manage Your Finances</h2>
      <h1>
        <b>Bank Accounts in Excel</b>
      </h1>

      <div className="cta-header">
        <div className="signup-login-btn">
          <a href="/signup" className="cta-button">
            Get Started Now
          </a>

          <a href="/Login" className="cta-button">
            Login
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
